import React from 'react'
import { graphql } from "gatsby"
import {
  Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import Layout from '../components/layout'



import './../css/pages.css'

const TermsPage = () => (
    <NamespacesConsumer>
    {(t, { i18n }) => {
        return (
            // <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000131")}`, url:this.props.location.href } }>
            <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000131")}` } }>
              <div id="bodyLaw" className="body-page ">
                <Container className="wrapper">

                  <main role="main" className="main">
                    <article className="content">
                      <h2 id="Terms-and-Conditions">Terms and Conditions</h2>
                      <br/>
                      <h6 id="Last-updated-25th-June-2017">Last updated: 25th June, 2017</h6>
                      <p>Welcome to the Acid Rain World official website, owned and operated by SK Ronex Limited and Aither Entertainment Limited
                          (“us”, “we”, “our” or “SK Ronex &amp; Aither ”). Thank you for visiting and have a great time!</p>
                      <p>Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the
                          <a href="http://www.acidrainworld.com"
                              target="_blank" rel="noopener ">http://www.acidrainworld.com</a> website (the “Site”).</p>
                      <p>Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms. These Terms apply
                          to all visitors, users and others who access or use the Site.</p>
                      <p>By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the terms then you
                          may not access the Site.</p>

                      <h3 id="Trademarks-and-Copyrights">Trademarks and Copyrights</h3>
                      <p>This Site and materials incorporated by us on this Site (“Material”) are protected by copyrights, patents, trade secrets
                          or other proprietary rights (“Copyrights”). Some of the characters, logos or other images incorporated by SK Ronex &amp;
                          Aither on this Site are also protected as registered or unregistered trademarks, trade names and/or service marks owned
                          by SK Ronex &amp; Aither or others (“Trademarks”). SK Ronex &amp; Aither respect the intellectual property rights of
                          others and asks users of this Site to do the same.</p>

                      <h3 id="Personal-Use-of-Material">Personal Use of Material</h3>
                      <p>Your right to make use of this Site and any Material or other content appearing on it is subject to your compliance with
                          these Terms of Use. Modification or use of the Material or any other content on this Site for any purpose not permitted
                          by these Terms of Use may be a violation of the Copyrights and/or Trademarks and is prohibited.</p>
                      <p>You may access and display Material and all other content displayed on this Site for non-commercial, personal, entertainment
                          use on a single computer only. The Material and all other content on this Site may not otherwise be copied, reproduced,
                          republished, uploaded, posted, transmitted, distributed or used in any way unless specifically authorized by SK Ronex
                          &amp; Aither. Any authorization to copy Material granted by SK Ronex &amp; Aither in any part of this Site for any reason
                          is restricted to making a single copy for non-commercial, personal, entertainment use on a single computer only, and
                          is subject to your keeping intact all copyright and other proprietary notices. Using any Material on any other web site
                          or networked computer environment is prohibited. Also, decompiling, reverse engineering, disassembling, or otherwise
                          reducing the code used in any software on this Site into a readable form in order to examine the construction of such
                          software and/or to copy or create other products based (in whole or in part) on such software, is prohibited.</p>

                      <h3 id="Links-To-Other-Web-Sites">Links To Other Web Sites</h3>
                      <p>Our Site may contain links to third-party web sites or services that are not owned or controlled by SK Ronex &amp; Aither.</p>
                      <p>We have no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party
                          web sites or services. You further acknowledge and agree that SK Ronex &amp; Aither shall not be responsible or liable,
                          directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance
                          on any such content, goods or services available on or through any such web sites or services.</p>
                      <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that
                          you visit.</p>

                      <h3 id="Termination">Termination</h3>
                      <p>We may terminate or suspend access to our Site immediately, without prior notice or liability, for any reason whatsoever,
                          including without limitation if you breach the Terms.
                          <br/>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without
                          limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                      <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including
                          without limitation if you breach the Terms.</p>
                      <p>Upon termination, your right to use the Site will immediately cease. If you wish to terminate your account, you may simply
                          discontinue using the Site.</p>
                      <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without
                          limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                      
                      <h3 id="Enquiries-Feedback-and-Submissions">Enquiries, Feedback and Submissions</h3>
                      <p>You can submit questions, comments, feedback, suggestions, ideas, or other information about SK Ronex &amp; Aither, our Sites
                          and our products (collectively, “Ideas”). Ideas, provided to SK Ronex &amp; Aither by email or otherwise are entirely
                          voluntary, non-confidential, gratuitous and non-committal. Aither shall own exclusive rights, including all intellectual
                          property rights, and shall be entitled to the unrestricted use and dissemination of Ideas for any purpose, commercial
                          or otherwise, without acknowledgment or compensation to you.</p>
                      <p>You agree that you are and shall remain solely responsible for the contents of any submissions you make, and you will not
                          submit material that is unlawful, defamatory, abusive or obscene. You agree that you will not submit anything to the
                          site that will violate any right of any third party, including copyright, trademark, privacy or other personal or proprietary
                          right(s).</p>
                      <p>Any material you submit to this site will be deemed a grant of a royalty free non-exclusive right and license to use, reproduce,
                          modify, display, transmit, adapt, publish, translate, create derivative works from and distribute these materials throughout
                          the universe in any medium and through any methods of distribution, transmission and display whether now known or hereafter
                          devised.</p>

                      <h3 id="Governing-Law">Governing Law</h3>
                      <p>These Terms shall be governed and construed in accordance with the laws of Hong Kong, without regard to its conflict of law
                          provisions.</p>
                      <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision
                          of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain
                          in effect. These Terms constitute the entire agreement between us regarding our Site, and supersede and replace any prior
                          agreements we might have between us regarding the Site.</p>

                      <h3 id="Changes">Changes</h3>
                      <p>Product specifications, details, release dates and pricing on this Site are subject to change without further notice. We
                          reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we
                          will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change
                          will be determined at our sole discretion.</p>
                      <p>By continuing to access or use our Site after those revisions become effective, you agree to be bound by the revised terms.
                          If you do not agree to the new terms, please stop using the Site.</p>

                      <h3 id="Contact-Us">Contact Us</h3>
                      <p>If you have any questions about these Terms, please contact us.</p>
                    </article>
                  </main>




                </Container>
              </div>

            </Layout>
        )
    }}
    </NamespacesConsumer>
)

export default withI18next()(TermsPage)

export const query = graphql`
query TermsLng($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`
